import Vue from 'vue'
import axios from 'axios'
import store from '../store/index'
import md5 from 'md5'
import router from '../router/index'

const service = axios.create({
    // baseURL: 'http://159.138.236.92', // app接口
    baseURL: process.env.API_ROOT,
    timeout: 5000000 // 请求超时时间
})

service.interceptors.request.use(config => {
    // if (localStorage.getItem('token')){
    //   config.headers['token'] = localStorage.getItem('token')
    // }

    return config
}, error => {

    Promise.reject(error)
})
service.interceptors.response.use(
    response => {
        if (response.data.code == 4001) {
            router.replace({path: '/login'})
        } else {
            // alert(response.data.errorMsg);
            return response.data
        }
        // else if(response.data.errorCode == 1007){
        //   // alert(1)
        //   localStorage.setItem('userInfo','')
        //   store.commit('setUserInfo',{})
        //   localStorage.setItem('token','')
        //   // router.replace({ path: '/loginTo' })
        // }
    },
    error => {
        // let text = JSON.parse(JSON.stringify(error)).response.status === 404 ? '404' : '当前访问人数过多,请重试'
        // Vue.$vux.toast.show({
        //   type: 'text',
        //   text: text,
        //   position: 'middle',
        //   width: '7rem'
        // })
        return Promise.reject(error)
    }
)
export {
    service as axios
}
