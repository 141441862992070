import Vue from 'vue'
import Vuex from 'vuex'
import {userPointers} from "@/api/dashboard/giftUser"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {},//用户信息
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setSocialUrl(state, socialUrl) {
            state.socialUrl = socialUrl
            state.expiredTime = new Date().getTime() + 1000 * 60 * 60 * 24 * 7
        }
    },
    actions: {
        refreshUserInfo({commit}) {
            userPointers(
                (res) => {
                    commit('setUserInfo', res.data)
                },
                (err) => {
                    console.log(err)
                }
            )
        }
    },
    modules: {}
})
