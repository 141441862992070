import {axios} from '@/utils/request'
// Home-获取标签
export const labelList = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/products/product_tags/',
        params: {limit: 100}
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// Home-获取标签
export const getProductTag = (params, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/products/products/get_recommend_product/',
        params,
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}


// Discord登陆
export const getUserByCode = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/app/login/getUserByCode',
        data: msg
    })
        .then(function (res) {
            if (res.errorCode === 0) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res.errorMsg)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

//产品列表-按分类展示
export const productList = (sucFn, errorFn) => {
    axios({
        method: 'get',
        params: {
            limit: 100,
            active: true,
            order_page: true
        },
        url: '/api/products/products/',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
export const productListAll = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/products/products/',
        params: {
            limit: 100,
            active: true,
            order_page: true
        }
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
//tap页签内容
export const tapList = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/products/product_collections/',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

//订单列表
export const orderList = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/app/order/list',
        data: msg
    })
        .then(function (res) {
            if (res.errorCode === 0) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res.errorMsg)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
//Home-根据标签获取产品(取数组第一个)
export const labelProduct = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/app/home/label/submit',
        data: msg
    })
        .then(function (res) {
            if (res.errorCode === 0) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res.errorMsg)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
//创建订单
export const createOrder = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/orders/checkout',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
// 检查订单是否支付成功
export const checkOrderStatus = (orderNumber) => {
    return axios.get('/api/orders/order_list/get_status/', {
        params: {
            order_id: orderNumber
        }
    })
        .then(response => {
            const status = response.data.status

            if (status === 1) {
                // 已支付，返回支付成功
                return 'success'
            } else {
                // 未支付，继续轮询
                return 'pending'
            }
        })
        .catch(error => {
            console.log(error)
        })
}
//获取社交媒体url
export const getSocialUrl = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/settings/social',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// 获取第二个弹窗的选择数据
export const getProductsStock = (params,sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/products/products/stock/',
        data: params
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

// 获取库存和价格
export const getProductsVariantInfo = (params,sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/products/products/variant_info/',
        data: params
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}