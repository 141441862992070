import {axios} from '@/utils/request'

// 礼品卡页面  获取礼品卡数据
export const rewardPromo = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/rewards/coupon_code/',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
// 查询积分变动列表数据
export const rewardData = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/rewards/gift_card/base-info',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
// 查询积分变动列表数据
export const pointsChangeDatas = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/rewards/point_record/',
        params: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
// 查询积分等级码配置
export const pointersLevel = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/rewards/level_code/',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}
// 查询用户积分
export const userPointers = (sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/users/u/user_info/',
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}

export const exchangeGift = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: '/api/rewards/gift_card/exchange/',
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })
}