<template>
    <div class="footer">
        <div class="wrap clearfix">
            <a href="#" class="logo" @click="toUrl('/')"><img src="@/assets/images/logo1.png" alt=""></a>
            <div class="rts clearfix">
                <div class="nav clearfix">
                    <ul>
                        <li @click="toUrl('/')"><a>Home</a></li>
                        <li @click="toUrl('/product')"><a>Product</a></li>
                        <li @click="toUrl('/dashboard')"><a>Dashboard</a></li>
                        <li @click="toUrl('/support')"><a>Support</a></li>
                    </ul>
                    <ul>
                        <li @click="toDeail('policy',3)"><a>Terms of Use</a></li>
                        <li @click="toDeail('policy',2)"><a>Refund Policy</a></li>
                        <li @click="toDeail('policy',1)"><a>Privacy Policy</a></li>
                    </ul>
                </div>
                <div class="ne">
                    <ul>
                        <li @click="toOpen(socialUrl.twitter)"><a><img src="@/assets/images/fn2.png" alt=""></a></li>
                        <li @click="toOpen(socialUrl.discord)"><a><img src="@/assets/images/fn3.png" alt=""></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getSocialUrl} from '@/api'

export default {
    data() {
        return {
            socialUrl: {},
        }
    },
    mounted() {
        this.getSocialUrls()
    },
    methods: {
        toUrl(url) {
            this.$router.push({path: url})
        }, toDeail(name, id) {
            const {href} = this.$router.resolve({name: name, query: {id: id}})
            window.open(href, '_blank')
        },
        toOpen(url) {
            window.open(url, '_blank')
        },
        getSocialUrls() {
            // 从缓存中获取
            this.socialUrl = this.$store.state.socialUrl
            if (this.socialUrl && this.socialUrl.expiredTime > new Date().getTime()) {
                return
            }
            getSocialUrl(
                res => {
                    this.socialUrl = res.data
                    this.$store.commit('setSocialUrl', res.data, {root: true})
                },
                err => {
                    console.log(err)
                }
            )
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

