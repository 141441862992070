import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
    },
    {
        path: '/createAccount',
        name: 'createAccount',
        component: () => import(/* webpackChunkName: "about" */ '../views/createAccount.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import(/* webpackChunkName: "about" */ '../views/product.vue')
    },
    {
        path: '/support',
        name: 'support',
        component: () => import(/* webpackChunkName: "about" */ '../views/support.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard.vue')
    },
    {
        path: '/dashboard/index',
        name: 'dashboardIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue'),
        children: [
            {
                path: "rewards",
                name: "rewards",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/rewards.vue'),
            },
            {
                path: "userlist",
                name: "userList",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/userList.vue'),
            },
            {
                path: "usergroup",
                name: "userGroup",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/userGroup.vue'),
            },
            {
                path: "tickets",
                name: "tickets",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/tickets.vue'),
            },
            {
                path: "aclmanager",
                name: "AclManager",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/aclmanager.vue'),
            },
            {
                path: "invite",
                name: "invite",
                component: () => import(/* webpackChunkName: "about" */ '../views/invite.vue'),
            },
            {
                path: "websiteSettings",
                name: "WebsiteSettings",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/websiteSettings.vue'),
            },
            {
                path: "setting",
                name: "userSettings",
                component: () => import(/* webpackChunkName: "about" */ '../views/settings.vue'),
            }
            ,
            {
                path: "orders",
                name: "Orders",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/orders.vue'),
            }
            ,
            {
                path: "promocodeUser",
                name: "promocodeUser",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/promocode-user.vue'),
            },
            {
                path: "promocode",
                name: "promocode",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/promocode.vue'),
            },
            {
                path: "giftcardUser",
                name: "giftcardUser",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/giftcard-user.vue'),
            },
            {
                path: "giftcard",
                name: "giftcard",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/giftcard.vue'),
            }
            ,
            {
                path: "leveldiscount",
                name: "leveldiscount",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/leveldiscount.vue'),
            }
            ,
            {
                path: "proxies/:id?",
                name: "Proxies",
                component: () => import(/* webpackChunkName: "about" */ '../views/proxies.vue'),
            }
            ,
            {
                path: "qa",
                name: "QA",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/QA.vue'),
            }
            ,
            {
                path: "servers",
                name: "servers",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/servers.vue'),
            }
            ,
            {
                path: "servergroup",
                name: "servergroup",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/servergroup.vue'),
            }
            ,
            {
                path: "CIDRManage",
                name: "CIDRManage",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/CIDRManage.vue'),
            }
            ,
            {
                path: "AclGroupManager",
                name: "AclGroupManager",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/AclGroupManager.vue'),
            }
            ,
            {
                path: "productManager",
                name: "productManager",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/productManager.vue'),
            }
            ,
            {
                path: "productType",
                name: "productType",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/productType.vue'),
            }
            ,
            {
                path: "productTag",
                name: "productTag",
                component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/productTag.vue'),
            }

        ]
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import(/* webpackChunkName: "about" */ '../views/policy.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router
