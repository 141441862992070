<template>
  <div id="app">
    <router-view />
    <bottom-com></bottom-com>
  </div>
</template>

<script>
import bottomCom from '@/components/bottom'
export default {
  props: {

  },
  components: {
    bottomCom
  },
  created() {
    //发送ws
    //在页面加载时读取sessionStorage里的状态信息
    if (localStorage.getItem("store")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem("store"))))
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state))
    })
  },
  mounted() {

  },
  methods: {

    getUrlName(name) {
      /* eslint-disable */
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    },
  }
}
</script>

<style>
#app {
  font-family: 'Pang';
}
.el-message {
    z-index: 200000!important;
}
</style>
